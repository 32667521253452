<template>
    <!-- About section start -->
	<section id="about-section" class="px-12 lg:px-32 py-16 border-t border-stone-500 relative">
        <!-- Main text start -->
		<h1 class="uppercase text-5xl mb-4 font-semibold ">
			ABOUT US
		</h1>
		<p class="capitalize xl:w-1/2 text-stone-500 mb-8">
			Founded in 2024, Imocou Tech leverage advanced artificial intelligence technologies to revolutionize social media management. Our cutting-edge platform empowers businesses to optimize their social media presence by automating content creation, scheduling, and audience engagement. Utilizing machine learning algorithms, we provide deep insights into social media trends, enabling brands to make data-driven decisions and enhance their online visibility. Our team of AI experts is dedicated to ensuring seamless integration and delivering personalized strategies that drive growth. With AI Social Solutions, managing your social media is effortless and efficient, allowing you to focus on what truly matters—growing your business.
		</p>
		<!-- Main text end -->

        <!-- "More" link start -->
        <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>Read more </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </a>
        <!-- "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-200 h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- About section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>