<template>
    <!-- Footer start -->
    <div class="bg-stone-700 w-full flex justify-center items-center md:justify-evenly flex-col md:flex-row gap-8 p-8">
        <!-- Overview start -->
        <div class="text-stone-100 text-center">
            <p class="tracking-widest font-semibold text-stone-100">IMOCOU TECH</p>
			<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备20030048号-1</a>
            <!-- <p class="tracking-widest font-light text-stone-100">© Meike Shunwei Technology Co., Ltd</p> -->
        </div>
        <!-- Overview end -->

        <!-- Links start -->
        <div class="text-stone-100 flex justify-center items-center gap-8">
			<!-- <a href="#">
				<i class="fa-brands fa-instagram socialIcon"></i>
			</a> -->
			<!-- <a href="#">
				<i class="fa-brands fa-linkedin socialIcon"></i>
			</a> -->
			<a href="#">
				<i class="fa-brands fa-facebook socialIcon"></i>
			</a>
			<!-- <a href="#">
				<i class="fa-brands fa-dribbble socialIcon"></i>
			</a> -->
			<!-- <a href="#">
				<i class="fa-brands fa-pinterest socialIcon"></i>
			</a> -->
        </div>
        <!-- Links end -->
    </div>
    <!-- Footer end -->
</template>

<script>
export default {

}
</script>

<style>

</style>